$colors: (
        base: $base-color,
        primary: $primary-color,
        secondary: $secondary-color,
        grey: $grey-color,
        clear: $clear-color
);

@each $index, $color in $colors {
  .uk-tile-#{$index} {
    background-color: $color !important;
  }
  .uk-text-#{$index} {
    color: $color !important;
    * {
      color: $color;
    }
  }
}
// GLOBAL STYLES
// MARGIN
$edges: ('top', 'right', 'bottom', 'left');
@each $index in $edges {
  .b-margin-#{$index} {
    margin-#{$index}: 3vw;
  }
  .b-padding-#{$index} {
    padding-#{$index}: 3vw;
  }
}

//OTHER
img.j-svg {
  height: 0;
  min-height: 0;
}

.g-text-normalcase {
  text-transform: none !important;
}

.g-text-unstressed {
  text-decoration: none !important;
  &:hover {
    text-decoration: none !important;
  }
}

.g-cursor-pointer {
  cursor: pointer;
}