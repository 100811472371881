.m-content {

  &_correct {
    @include media-up($point-medium) {
      margin-top: -20px;
    }
    @include media-down($point-medium) {
      margin-top: -40px;
    }
  }

  @include media-up($point-extra-large) {
    h2.b-long {
      line-height: normal;
    }
  }


  @include media-up($point-medium) {
    font-size: calc(min(1.6vw, 26px));
    line-height: 1.8vw;
    h2 {
      font-size: 2.8vw;
      line-height: calc(min(3.5rem, 55px));
      &.uk-heading-line {
        line-height: normal;
      }
    }
  }
  @include media-down($point-medium) {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    h2 {
      font-size: 20px;
      line-height: 30px;
    }
  }

  ul {
    li {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.b-slogan {
  margin-top: 5vh;
  padding: 0 2.3vw;
  text-align: center;
  text-transform: uppercase;
  font-weight: 400;
  @include media-up($point-medium) {
    font-size: 4vw;
    line-height: 6.5vw;
  }
  @include media-down($point-medium) {
    font-size: 6.5vw;
    line-height: 8.5vw;
  }
}

.b-button {
  margin-top: 6vw;
  text-transform: none;
  border: 3px solid $clear-color;
  background-color: rgba($primary-color, 0.4);
  color: $clear-color;

  &:hover {
    color: $clear-color;
    background-color: rgba($primary-color, 0.8);
  }

  @include media-up($point-medium) {
    padding: 0.2em 1.7em 0.29em;
    font-size: 2.5vw;
  }
  @include media-down($point-medium) {
    padding: 0 60px;
    font-size: 20px;
  }
}

.b-animation {
  top: -80px;
  animation: imj 10s infinite;
  background-image: url(../img/clouds.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 105vh;
  width: 100vw;
}

@keyframes imj {
  0% {
    background-image: url(../img/clouds.jpg) center / cover no-repeat;
  }

  50% {
    background-image: url(../img/mountain-black.png);
  }

  100% {
    background-image: url(../img/clouds.jpg);
  }
}