.image_1 {
  @include media-up($point-medium) {
    margin-top: 2vh;
    padding-right: 3vw;
  }

  img {
    border-radius: 13px;
    border-radius: 13px;
    box-shadow: 16px 13px 1px $third-color;
  }

  & + div.b-border {
    margin-top: calc(3vw + 25px);
  }
}

.image_3 {
  @include media-up($point-medium) {
    margin-top: 2vh;
  }
  @include media-down($point-medium) {
    padding-right: 2vw;
  }
  @include media-down($point-small) {
    padding-right: 0;
  }

  img {
    border-radius: 13px;
    border-radius: 13px;
    box-shadow: 20px -20px 1px $fourth-color;
  }
}