@mixin media-down($point) {
  @media (max-width: ($point - 1)) {
    @content;
  }
}

@mixin media-up($point) {
  @media (min-width: ($point)) {
    @content;
  }
}

@mixin transition($prop:all, $speed:0.2s, $delay:0.3s) {
  transition: $prop $speed ease-in-out $delay;
}
