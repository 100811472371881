.uk-navbar-sticky {
  @include media-up($point-medium) {
    border-bottom: 1px solid rgba($secondary-color, .8) !important;
  }
}

#menubar {
  .uk-navbar-container {

    @include media-up($point-medium) {
      background-color: transparent;
    }
    @include media-down($point-medium) {
      //background-color: rgba($clear-color, .8);
      background-color: transparent;
    }

    &.uk-navbar-sticky {
      @include media-up($point-medium) {
        background-color: rgba($clear-color, .8) !important;
      }
      @include media-down($point-medium) {
        background-color: transparent;
      }
    }
  }

  .uk-navbar {
    li {
      a {
        margin: 20px 0;
        padding: 0 10px;
        min-height: 40px;
        color: $clear-color;
        text-transform: uppercase;
        background-color: $secondary-color;
        border-radius: 20px;
        font-weight: 400;
        text-align: left;
        @extend .f-size-small;
        @include transition(all, 0.2s, 0);

        &:hover {
          background-color: $primary-color;
          color: $clear-color;
        }
        @include media-down($point-medium) {
          font-size:12px;
          text-align: center;
        }
      }

      &.uk-active {
        a {
          background-color: $clear-color;
          color: $base-color;
        }
      }
    }
  }
}

.uk-offcanvas-bar {
  background-color: $primary-color;
}


