.b-border {
  border-bottom: 1px $base-color solid;
}

.b-subcaption {
  font-size: 60%;
  text-transform: none;
}

#leafs {
  margin-top: -325px;
}

.b-block {
  &_1 {
    padding-left: 3vw;
    @include media-up($point-medium) {
      position: relative;
      top: -40px;
      padding: 20px 0 20px 3vw;
    }
    border-left: 1px $base-color solid;
  }

  &_2 {
    @include media-up($point-small) {
      margin-top: 7vw;
      padding-left: 3vw;
    }
    @include media-up($point-medium) {
      margin-top: 0;
    }
  }

  &_3 {
    @include media-down($point-small) {
      > div:first-child {
        padding-right: 3vw;

        > div:first-child {
          padding-right: 10px;
          border-right: 1px $base-color solid;
        }
      }
    }
  }

  &_5 {
    @include media-up($point-medium) {
      position: relative;
      top: -80px;
    }
  }

  &_6 {
    > div:last-child {
      @include media-down($point-medium) {
        margin-top: 10px;
      }
    }
  }

  &_7 {
    &_1 {
      padding: 10px;
      text-indent: 10px;
      background-color: rgb(235, 225, 225);
      border-radius: 20px;
      box-shadow: gray 6px 3px 4px;
      position: relative;
      z-index: 2;

      @include media-up($point-medium) {
        width: max-content;
        top: -3vh;
        left: 16vw;
      }
      @include media-down($point-medium) {
        width: auto;
      }
    }

    &_2 {
      background-color: rgb(203, 213, 202);
      border-radius: 20px;
      box-shadow: gray 6px 3px 4px;
      @include media-up($point-small) {
        padding: 50px 20px 25px 40px;
      }
      @include media-down($point-small) {
        position: relative;
        top: -45px;
        padding: 20px 20px 25px 20px;
        z-index: 1;
      }
    }
  }

  &_8 {
    @include media-up($point-medium) {
      padding: 1.5em;
    }
    @include media-down($point-medium) {
      padding: 2.5em;
    }
    border-radius: 69px;

    &_1 {
      background-color: rgb(237, 218, 218);
      box-shadow: rgb(198, 191, 206) 16px 13px 0px;
    }

    &_2 {
      background-color: rgb(234, 234, 234);
      box-shadow: rgb(226, 182, 182) 16px 13px 0px;
    }

    &_3 {
      background-color: rgb(234, 234, 234);
      box-shadow: rgb(192, 206, 191) -16px 13px 0px;
    }
  }

  &_9 {
    h2 {
      @include media-up($point-small) {
        margin-top: -25px;
      }
      @include media-down($point-small) {
        margin-top: 0;
      }
    }
  }

  &_10 {
    border-radius: 60px;

    &_1 {
      background-color: rgb(235, 224, 224);
      box-shadow: rgb(227, 199, 202) 13px 0px 0px;
    }

    &_2 {
      background-color: rgb(235, 224, 224);
      box-shadow: rgb(128, 160, 208) 14px 0px 0px;
    }

    > div:first-child {
      padding-bottom: 20px;
      @extend .b-border;
    }

    > div:last-child {
      padding-top: 20px;
    }
  }

  &_11 {
    @include media-down($point-medium) {
      position: relative;
      top: -20px;
    }
  }

  $h2Output: 40px;
  @include media-up($point-small) {
    &_h2_1 {
      > small {
        position: relative;
        top: 20px;
      }

      & + p {
        margin-top: $h2Output;
      }
    }

    &_h2_2 {
      position: relative;
      top: 40px;

      & + p {
        margin-top: $h2Output;
      }
    }
  }
  @include media-down($point-small) {
    &_h2_1 {
      margin-bottom: 0;

      > small {
        position: static;
        display: block;
      }

      & + p {
        margin-top: 0;
      }
    }

    &_h2_2 {
      position: static;
      margin-top: 0;

      & + p {
        margin-top: 0;
      }
    }
  }
}

#methods {
  @include media-up($point-small) {
    margin-top: 40px;
  }
}

@include media-down($point-small) {
  #price {
    .uk-grid {
      .uk-first-column:last-child {
        margin-top: 0;
      }
    }
  }
}

//FORM
#contact {
  form {
    margin-top: 8vh;
    margin-bottom: 8vh;
    border-radius: 26px;
    box-shadow: grey 5px 5px 15px;
    background-color: rgb(139, 167, 211);
  }
}