body {
  quotes: "\201c" "\201d";
  background-color: $grey-color;
  color: $base-color;
  @extend .f-family;
}

* {
  &:focus, &:active {
    outline: none;
    color: inherit;
    text-decoration: none !important;
  }

  &::selection {
    background-color: $primary-color;
  }

  :hover {
    outline: none;
  }
}

a {
  &:hover {
    color: $primary-color;
  }
}

.uk-h1, .uk-h2, .uk-h3, .uk-heading-2xlarge, .uk-heading-3xlarge, .uk-heading-large, .uk-heading-medium, .uk-heading-small, .uk-heading-xlarge, h1, h2, h3 {
  @extend .f-caption;
  @extend .f-size-default;
  color: $base-color;
  text-transform: uppercase;
}

.uk-h4, .uk-h5, .uk-h6, h4, h5, h6 {
  @extend .f-family;
  @extend .f-size-default;
  color: $base-color;
  text-transform: uppercase;
}

.uk-input, .uk-textarea {
  color: $clear-color !important;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px white solid !important;
  background-color: transparent !important;
}

//INPUT PLACEHOLDER
$placeholderColor: rgba($clear-color, .7);

input[placeholder] {
  text-overflow: ellipsis;
}

input::-moz-placeholder, input:-moz-placeholder, input:-ms-input-placeholder {
  text-overflow: ellipsis;
}

::placeholder {
  color: $placeholderColor !important;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12 -18 */
  color: $placeholderColor !important;
}

// UIKIT
@include media-up($point-medium) {
  .uk-container {
    padding: 0 60px;
  }
}

@include media-down($point-medium) {
  .uk-container {
    padding-left: 20px;
    padding-right: 20px;
  }
  address, dl, fieldset, figure, ol, p, pre, ul {
    margin: 0 0 10px;
  }
  address, dl, fieldset, figure, ol, p, pre, ul {
    margin: 0 0 10px 0;
  }
}

.uk-button {
  font-size: inherit;

  &.uk-button-primary {
    background-color: $primary-color;

    &:hover {
      background-color: $secondary-color;
    }
  }
}

.uk-h1, h1 {
  @include media-down($point-medium) {
    font-size: 1.6rem;
  }
}

.uk-h2, h2 {
  @include media-up($point-medium) {
    font-size: 1.7rem;
    line-height: 1.3;
  }
  @include media-down($point-medium) {
    font-size: 1.5rem;
    line-height: 1.2;
  }
}

[class*=uk-align] {
  @include media-up($point-medium) {
    margin-bottom: 30px;
  }
  @include media-down($point-medium) {
    margin-bottom: 5px;
  }
}

.uk-button-large {
  @include media-down($point-small) {
    padding: 0 10px;
  }
}

hr {
  border-top-color: $base-color;
}

.uk-offcanvas-bar .uk-close {
  color: $clear-color;
}

@include media-down($point-small) {
  .uk-text-large {
    font-size: 1.0rem;
    line-height: 1.1;
  }
}

.uk-heading-line > ::after, .uk-heading-line > ::before {
  border-bottom: 1px solid black;
}

.uk-grid-divider > :not(.uk-first-column)::before {
  top: 20%;
  height: 60%;
  border-left: 1px solid black;
  @include media-down($point-medium){
    display: none;
  }
}