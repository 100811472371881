.uk-tile-base {
  background-color: black !important; }

.uk-text-base {
  color: black !important; }
  .uk-text-base * {
    color: black; }

.uk-tile-primary {
  background-color: #ADBBDD !important; }

.uk-text-primary {
  color: #ADBBDD !important; }
  .uk-text-primary * {
    color: #ADBBDD; }

.uk-tile-secondary {
  background-color: #FFAC72 !important; }

.uk-text-secondary {
  color: #FFAC72 !important; }
  .uk-text-secondary * {
    color: #FFAC72; }

.uk-tile-grey {
  background-color: #F2EAEA !important; }

.uk-text-grey {
  color: #F2EAEA !important; }
  .uk-text-grey * {
    color: #F2EAEA; }

.uk-tile-clear {
  background-color: white !important; }

.uk-text-clear {
  color: white !important; }
  .uk-text-clear * {
    color: white; }

.b-margin-top {
  margin-top: 3vw; }

.b-padding-top {
  padding-top: 3vw; }

.b-margin-right {
  margin-right: 3vw; }

.b-padding-right {
  padding-right: 3vw; }

.b-margin-bottom {
  margin-bottom: 3vw; }

.b-padding-bottom {
  padding-bottom: 3vw; }

.b-margin-left {
  margin-left: 3vw; }

.b-padding-left {
  padding-left: 3vw; }

img.j-svg {
  height: 0;
  min-height: 0; }

.g-text-normalcase {
  text-transform: none !important; }

.g-text-unstressed {
  text-decoration: none !important; }
  .g-text-unstressed:hover {
    text-decoration: none !important; }

.g-cursor-pointer {
  cursor: pointer; }

.f-caption, .uk-h1, .uk-h2, .uk-h3, .uk-heading-2xlarge, .uk-heading-3xlarge, .uk-heading-large, .uk-heading-medium, .uk-heading-small, .uk-heading-xlarge, h1, h2, h3 {
  font-family: "Bad Script", cursive;
  font-weight: 400;
  font-style: normal; }

.f-family, body, .uk-h4, .uk-h5, .uk-h6, h4, h5, h6 {
  font-family: "Jost", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal; }

.f-size-large {
  font-size: 47px; }

.f-size-default, .uk-h1, .uk-h2, .uk-h3, .uk-heading-2xlarge, .uk-heading-3xlarge, .uk-heading-large, .uk-heading-medium, .uk-heading-small, .uk-heading-xlarge, h1, h2, h3, .uk-h4, .uk-h5, .uk-h6, h4, h5, h6 {
  font-size: 25px; }

.f-size-small, #menubar .uk-navbar li a {
  font-size: 16px; }

.font-bold {
  font-weight: 400; }

body {
  quotes: "\201c" "\201d";
  background-color: #F2EAEA;
  color: black; }

*:focus, *:active {
  outline: none;
  color: inherit;
  text-decoration: none !important; }

*::selection {
  background-color: #ADBBDD; }

* :hover {
  outline: none; }

a:hover {
  color: #ADBBDD; }

.uk-h1, .uk-h2, .uk-h3, .uk-heading-2xlarge, .uk-heading-3xlarge, .uk-heading-large, .uk-heading-medium, .uk-heading-small, .uk-heading-xlarge, h1, h2, h3 {
  color: black;
  text-transform: uppercase; }

.uk-h4, .uk-h5, .uk-h6, h4, h5, h6 {
  color: black;
  text-transform: uppercase; }

.uk-input, .uk-textarea {
  color: white !important;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px white solid !important;
  background-color: transparent !important; }

input[placeholder] {
  text-overflow: ellipsis; }

input::-moz-placeholder, input:-moz-placeholder, input:-ms-input-placeholder {
  text-overflow: ellipsis; }

::placeholder {
  color: rgba(255, 255, 255, 0.7) !important;
  opacity: 1;
  /* Firefox */ }

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: rgba(255, 255, 255, 0.7) !important; }

@media (min-width: 960px) {
  .uk-container {
    padding: 0 60px; } }

@media (max-width: 959px) {
  .uk-container {
    padding-left: 20px;
    padding-right: 20px; }
  address, dl, fieldset, figure, ol, p, pre, ul {
    margin: 0 0 10px; }
  address, dl, fieldset, figure, ol, p, pre, ul {
    margin: 0 0 10px 0; } }

.uk-button {
  font-size: inherit; }
  .uk-button.uk-button-primary {
    background-color: #ADBBDD; }
    .uk-button.uk-button-primary:hover {
      background-color: #FFAC72; }

@media (max-width: 959px) {
  .uk-h1, h1 {
    font-size: 1.6rem; } }

@media (min-width: 960px) {
  .uk-h2, h2 {
    font-size: 1.7rem;
    line-height: 1.3; } }

@media (max-width: 959px) {
  .uk-h2, h2 {
    font-size: 1.5rem;
    line-height: 1.2; } }

@media (min-width: 960px) {
  [class*=uk-align] {
    margin-bottom: 30px; } }

@media (max-width: 959px) {
  [class*=uk-align] {
    margin-bottom: 5px; } }

@media (max-width: 639px) {
  .uk-button-large {
    padding: 0 10px; } }

hr {
  border-top-color: black; }

.uk-offcanvas-bar .uk-close {
  color: white; }

@media (max-width: 639px) {
  .uk-text-large {
    font-size: 1.0rem;
    line-height: 1.1; } }

.uk-heading-line > ::after, .uk-heading-line > ::before {
  border-bottom: 1px solid black; }

.uk-grid-divider > :not(.uk-first-column)::before {
  top: 20%;
  height: 60%;
  border-left: 1px solid black; }
  @media (max-width: 959px) {
    .uk-grid-divider > :not(.uk-first-column)::before {
      display: none; } }

@media (min-width: 960px) {
  .uk-navbar-sticky {
    border-bottom: 1px solid rgba(255, 172, 114, 0.8) !important; } }

@media (min-width: 960px) {
  #menubar .uk-navbar-container {
    background-color: transparent; } }

@media (max-width: 959px) {
  #menubar .uk-navbar-container {
    background-color: transparent; } }

@media (min-width: 960px) {
  #menubar .uk-navbar-container.uk-navbar-sticky {
    background-color: rgba(255, 255, 255, 0.8) !important; } }

@media (max-width: 959px) {
  #menubar .uk-navbar-container.uk-navbar-sticky {
    background-color: transparent; } }

#menubar .uk-navbar li a {
  margin: 20px 0;
  padding: 0 10px;
  min-height: 40px;
  color: white;
  text-transform: uppercase;
  background-color: #FFAC72;
  border-radius: 20px;
  font-weight: 400;
  text-align: left;
  transition: all 0.2s ease-in-out 0; }
  #menubar .uk-navbar li a:hover {
    background-color: #ADBBDD;
    color: white; }
  @media (max-width: 959px) {
    #menubar .uk-navbar li a {
      font-size: 12px;
      text-align: center; } }

#menubar .uk-navbar li.uk-active a {
  background-color: white;
  color: black; }

.uk-offcanvas-bar {
  background-color: #ADBBDD; }

@media (min-width: 960px) {
  .b-footer {
    font-size: 16px; } }

@media (max-width: 959px) {
  .b-footer {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 12px;
    line-height: 14px; } }

.b-footer .uk-navbar li a {
  min-height: unset;
  color: black;
  text-transform: none;
  text-decoration: none;
  text-underline-offset: 5px;
  justify-content: left; }
  .b-footer .uk-navbar li a:hover {
    color: #ADBBDD;
    text-decoration: underline; }

.wave {
  width: 100vw;
  height: 9vh;
  padding-bottom: 1px;
  border-bottom: 2px solid whitesmoke;
  background-image: url(../img/wave.png); }

.uk-offcanvas-bar li a {
  min-height: unset;
  color: white;
  text-transform: none;
  text-underline-offset: 5px;
  justify-content: left; }

.uk-offcanvas-bar li.uk-active a {
  color: white;
  text-decoration: underline; }

@media (min-width: 960px) {
  .m-content_correct {
    margin-top: -20px; } }

@media (max-width: 959px) {
  .m-content_correct {
    margin-top: -40px; } }

@media (min-width: 1600px) {
  .m-content h2.b-long {
    line-height: normal; } }

@media (min-width: 960px) {
  .m-content {
    font-size: calc(min(1.6vw, 26px));
    line-height: 1.8vw; }
    .m-content h2 {
      font-size: 2.8vw;
      line-height: calc(min(3.5rem, 55px)); }
      .m-content h2.uk-heading-line {
        line-height: normal; } }

@media (max-width: 959px) {
  .m-content {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400; }
    .m-content h2 {
      font-size: 20px;
      line-height: 30px; } }

.m-content ul li {
  margin-bottom: 10px; }
  .m-content ul li:last-child {
    margin-bottom: 0; }

.b-slogan {
  margin-top: 5vh;
  padding: 0 2.3vw;
  text-align: center;
  text-transform: uppercase;
  font-weight: 400; }
  @media (min-width: 960px) {
    .b-slogan {
      font-size: 4vw;
      line-height: 6.5vw; } }
  @media (max-width: 959px) {
    .b-slogan {
      font-size: 6.5vw;
      line-height: 8.5vw; } }

.b-button {
  margin-top: 6vw;
  text-transform: none;
  border: 3px solid white;
  background-color: rgba(173, 187, 221, 0.4);
  color: white; }
  .b-button:hover {
    color: white;
    background-color: rgba(173, 187, 221, 0.8); }
  @media (min-width: 960px) {
    .b-button {
      padding: 0.2em 1.7em 0.29em;
      font-size: 2.5vw; } }
  @media (max-width: 959px) {
    .b-button {
      padding: 0 60px;
      font-size: 20px; } }

.b-animation {
  top: -80px;
  animation: imj 10s infinite;
  background-image: url(../img/clouds.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 105vh;
  width: 100vw; }

@keyframes imj {
  0% {
    background-image: url(../img/clouds.jpg) center/cover no-repeat; }
  50% {
    background-image: url(../img/mountain-black.png); }
  100% {
    background-image: url(../img/clouds.jpg); } }

.b-border, .b-block_10 > div:first-child {
  border-bottom: 1px black solid; }

.b-subcaption {
  font-size: 60%;
  text-transform: none; }

#leafs {
  margin-top: -325px; }

.b-block_1 {
  padding-left: 3vw;
  border-left: 1px black solid; }
  @media (min-width: 960px) {
    .b-block_1 {
      position: relative;
      top: -40px;
      padding: 20px 0 20px 3vw; } }

@media (min-width: 640px) {
  .b-block_2 {
    margin-top: 7vw;
    padding-left: 3vw; } }

@media (min-width: 960px) {
  .b-block_2 {
    margin-top: 0; } }

@media (max-width: 639px) {
  .b-block_3 > div:first-child {
    padding-right: 3vw; }
    .b-block_3 > div:first-child > div:first-child {
      padding-right: 10px;
      border-right: 1px black solid; } }

@media (min-width: 960px) {
  .b-block_5 {
    position: relative;
    top: -80px; } }

@media (max-width: 959px) {
  .b-block_6 > div:last-child {
    margin-top: 10px; } }

.b-block_7_1 {
  padding: 10px;
  text-indent: 10px;
  background-color: #ebe1e1;
  border-radius: 20px;
  box-shadow: gray 6px 3px 4px;
  position: relative;
  z-index: 2; }
  @media (min-width: 960px) {
    .b-block_7_1 {
      width: max-content;
      top: -3vh;
      left: 16vw; } }
  @media (max-width: 959px) {
    .b-block_7_1 {
      width: auto; } }

.b-block_7_2 {
  background-color: #cbd5ca;
  border-radius: 20px;
  box-shadow: gray 6px 3px 4px; }
  @media (min-width: 640px) {
    .b-block_7_2 {
      padding: 50px 20px 25px 40px; } }
  @media (max-width: 639px) {
    .b-block_7_2 {
      position: relative;
      top: -45px;
      padding: 20px 20px 25px 20px;
      z-index: 1; } }

.b-block_8 {
  border-radius: 69px; }
  @media (min-width: 960px) {
    .b-block_8 {
      padding: 1.5em; } }
  @media (max-width: 959px) {
    .b-block_8 {
      padding: 2.5em; } }
  .b-block_8_1 {
    background-color: #eddada;
    box-shadow: #c6bfce 16px 13px 0px; }
  .b-block_8_2 {
    background-color: #eaeaea;
    box-shadow: #e2b6b6 16px 13px 0px; }
  .b-block_8_3 {
    background-color: #eaeaea;
    box-shadow: #c0cebf -16px 13px 0px; }

@media (min-width: 640px) {
  .b-block_9 h2 {
    margin-top: -25px; } }

@media (max-width: 639px) {
  .b-block_9 h2 {
    margin-top: 0; } }

.b-block_10 {
  border-radius: 60px; }
  .b-block_10_1 {
    background-color: #ebe0e0;
    box-shadow: #e3c7ca 13px 0px 0px; }
  .b-block_10_2 {
    background-color: #ebe0e0;
    box-shadow: #80a0d0 14px 0px 0px; }
  .b-block_10 > div:first-child {
    padding-bottom: 20px; }
  .b-block_10 > div:last-child {
    padding-top: 20px; }

@media (max-width: 959px) {
  .b-block_11 {
    position: relative;
    top: -20px; } }

@media (min-width: 640px) {
  .b-block_h2_1 > small {
    position: relative;
    top: 20px; }
  .b-block_h2_1 + p {
    margin-top: 40px; }
  .b-block_h2_2 {
    position: relative;
    top: 40px; }
    .b-block_h2_2 + p {
      margin-top: 40px; } }

@media (max-width: 639px) {
  .b-block_h2_1 {
    margin-bottom: 0; }
    .b-block_h2_1 > small {
      position: static;
      display: block; }
    .b-block_h2_1 + p {
      margin-top: 0; }
  .b-block_h2_2 {
    position: static;
    margin-top: 0; }
    .b-block_h2_2 + p {
      margin-top: 0; } }

@media (min-width: 640px) {
  #methods {
    margin-top: 40px; } }

@media (max-width: 639px) {
  #price .uk-grid .uk-first-column:last-child {
    margin-top: 0; } }

#contact form {
  margin-top: 8vh;
  margin-bottom: 8vh;
  border-radius: 26px;
  box-shadow: grey 5px 5px 15px;
  background-color: #8ba7d3; }

@media (min-width: 960px) {
  .image_1 {
    margin-top: 2vh;
    padding-right: 3vw; } }

.image_1 img {
  border-radius: 13px;
  border-radius: 13px;
  box-shadow: 16px 13px 1px #C6BFCE; }

.image_1 + div.b-border, .b-block_10 > .image_1 + div:first-child {
  margin-top: calc(3vw + 25px); }

@media (min-width: 960px) {
  .image_3 {
    margin-top: 2vh; } }

@media (max-width: 959px) {
  .image_3 {
    padding-right: 2vw; } }

@media (max-width: 639px) {
  .image_3 {
    padding-right: 0; } }

.image_3 img {
  border-radius: 13px;
  border-radius: 13px;
  box-shadow: 20px -20px 1px #81a0d0; }
