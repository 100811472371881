//BREAKPOINTS
$point-extra-large: 1600px;
$point-large: 1200px;
//$point-tablet: 1024px + 1;
$point-medium: 960px;
$point-small: 640px;
//COLORS
$base-color: black;
$primary-color: #ADBBDD;
$secondary-color: #FFAC72;
$third-color: #C6BFCE;
$fourth-color: #81a0d0;
$grey-color: #F2EAEA;
$clear-color: white;