//FONT STYLES
.f-caption {
  //margin-left: 26vw;
  font-family: "Bad Script", cursive;
  font-weight: 400;
  font-style: normal;
}
.f-family {
  font-family: "Jost", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 900

//SIZES
$sizes: (
        'large':47,
        'default':25,
        'small':16
);
@each $index, $size in $sizes {
  .f-size-#{$index} {
    font-size: #{$size}px;
  }
}

.font-bold {
  font-weight: 400;
}


