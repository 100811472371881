.b-footer {
  @include media-up($point-medium) {
    font-size: 16px;
  }
  @include media-down($point-medium) {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 12px;
    line-height: 14px;
  }

  .uk-navbar {
    li {
      a {
        min-height: unset;
        color: $base-color;
        text-transform: none;
        text-decoration: none;
        text-underline-offset: 5px;
        justify-content: left;

        &:hover {
          color: $primary-color;
          text-decoration: underline;
        }
      }
    }
  }
}

.wave {
  width: 100vw;
  height: 9vh;
  padding-bottom: 1px;
  border-bottom: 2px solid whitesmoke;
  background-image: url(../img/wave.png);
}

.uk-offcanvas-bar {
  li {
    a {
      min-height: unset;
      color: $clear-color;
      text-transform: none;
      text-underline-offset: 5px;
      justify-content: left;
    }

    &.uk-active {
      a {
        color: $clear-color;
        text-decoration: underline;
      }
    }
  }
}

